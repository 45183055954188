<template>
  <v-container :fluid="true" class="synapsa-container px-0 py-0">
    <v-row class="mx-0">
      <v-col cols="12" class="px-0 pt-2">
        <v-btn
          small
          text
          :color="''"
          class="text-capitalize synapsa-tab log-tab mr-2"
          @mouseenter="feedUrlIconRoute = '/images/icons/green/sidebar/'"
          @mouseleave="
            feedUrlIconRoute = $vuetify.theme.dark
              ? '/images/icons/white/sidebar/'
              : '/images/icons/dark/sidebar/'
          "
          @click="openFeedUrlForm"
        >
          Feed url
          <v-avatar size="10" class="br-0 ml-1">
            <img
              :src="location + feedUrlIconRoute + 'url-list.svg'"
              alt="Add"
            />
          </v-avatar>
        </v-btn>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="mx-0">
      <v-col cols="12" class="px-2 py-2 mt-2 synapsa-tabs">
        <v-btn
          v-for="tab in tabs"
          v-permission="tab.permissions"
          :key="tab.id"
          :text="tab.id !== activeTab"
          :color="tab.id === activeTab ? 'primary' : ''"
          :outlined="tab.id === activeTab"
          small
          class="text-capitalize synapsa-tab"
          :class="tab.id === activeTab ? 'elevation-1' : ''"
          @click="to(tab)"
        >
          {{ $t(tab.name) }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-1">
        <router-view :id="parseInt($route.params.id)" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import permission from "@/directive/permission/permission.js";
export default {
  directives: { permission },
  data() {
    return {
      activeTab: "addressListsPredefinedIPv4",
      tabs: [
        {
          id: "addressListsPredefinedIPv4",
          name: "IPv4",
          route: "IPv4",
          permissions: ["view predefinedaddress"],
        },
        {
          id: "addressListsPredefinedIPv6",
          name: "IPv6",
          route: "IPv6",
          permissions: ["view predefinedaddress"],
        },
      ],

      location: "",
      feedUrlIconRoute: "/images/icons/dark/sidebar/",
    };
  },
  computed: {
    keywordAddForm: {
      get() {
        return this.$store.state.settings.keywordAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "keywordAddForm",
          value: val,
        });
      },
    },
    idAddForm: {
      get() {
        return this.$store.state.settings.idAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "idAddForm",
          value: val,
        });
      },
    },
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.activeTab = newValue.name;
      },
    },
    "$vuetify.theme.dark": {
      immediate: true,
      deep: true,
      handler() {
        this.feedUrlIconRoute = this.$vuetify.theme.dark
          ? "/images/icons/white/sidebar/"
          : "/images/icons/dark/sidebar/";
      },
    },
  },
  created() {
    this.location = window.location.origin;
  },
  methods: {
    to(tab) {
      this.$router.push(tab.route);
      this.activeTab = tab.id;
    },
    openFeedUrlForm() {
      this.keywordAddForm = "feedUrl";
      this.idAddForm = {
        type1: "addressList",
        type2: "predefined",
        type3:
          this.activeTab === "addressListsPredefinedIPv4" ? "ipv4" : "ipv6`",
        id: this.$route.params.id,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
$synapsaGreen: #69be28;
.log-tab {
  transition-duration: unset;
}
.log-tab.theme--light {
  background: #ffffff6e;
  box-shadow: 13px 13px 30px rgb(0 0 0 / 10%);
}
.log-tab.theme--dark {
  background: #1e1e1e96;
  box-shadow: 13px 13px 30px rgb(0 0 0 / 10%);
}
.log-tab:hover {
  color: $synapsaGreen;
}
</style>
